const FooterData = [
    {
        title: "Empresa",
        submenu: [
            /* //TODO:PN
            {
                title: "Quem Somos",
                link: "/quem-somos"
            }, */
            {
                title: "Contactos",
                link: "/contactos"
            },
        ]
    }
]

export default FooterData